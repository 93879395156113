import Vue from 'vue'
import { Component, Inject, Prop } from 'nuxt-property-decorator'
import { Validation } from '~/components/Validation'

@Component
export default class PasswordField extends Vue {
  @Inject() validation!: Validation
  @Prop() tabindex?: number
  showPassword = false

  focus() {
    (this.$refs.password as any).focus()
  }
}

Vue.component('password-field', PasswordField)
