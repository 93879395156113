import { Routes } from '~/components/Routes'
import isEmail from 'validator/lib/isEmail'
import Vue from 'vue'
import { Component, Inject, Watch } from 'vue-property-decorator'
import { Util } from '~/components/Util'
import { ILogInParams, ILogInResult } from 'backend/ApiTypes'
import { AxiosResponse } from 'axios';
import '~/components/layouts/FullscreenWithCard.vue'
import '~/components/atoms/inputs/PasswordField.vue'
import FullscreenWithCard from '~/components/layouts/FullscreenWithCard'

@Component({
  head: Util.metaFields({
    title: 'Log In | EQUIAM'
  }),
  middleware: 'unauthenticated'
})
export default class LogIn extends FullscreenWithCard {
  @Inject() routes!: Routes
  email = ''
  emailErrorMessages: string[] = []
  password = ''
  passwordErrorMessages: string[] = []
  codeErrorMessages: string[] = []
  loading = false
  code = ''
  state = ''
  numLoginFailed = 0
  showHasAnIssue = false

  created() {
    this.onRouteChange(this.$route)
    if (this.state === '2fa' && (!this.email || !this.password)) {
      this.$router.replace(this.routes.logIn)
    }
  }

  showIntercom() {
    this.showHasAnIssue = false
    window.Intercom && window.Intercom('show')
  }

  onSubmit() {
    // Reset the error messages
    this.emailErrorMessages = []
    this.passwordErrorMessages = []

    // Check for other errors. Inputs are trimmed by Vue
    if (!this.email) {
      this.emailErrorMessages = ['An email is required. Contact us if you forgot it']
      ;(this.$refs.email as HTMLElement).focus()
    } else if (!isEmail(this.email)) {
      this.emailErrorMessages = ['Your email address is invalid']
      ;(this.$refs.email as HTMLElement).focus()
    } else if (!this.password) {
      this.passwordErrorMessages = ['A password is needed']
      ;(this.$refs.password as HTMLElement).focus()
    } else if (!this.code && this.state === '2fa') {
      this.codeErrorMessages = ['A code is required. Contact us if you cannot obtain one']
      ;(this.$refs.code as HTMLElement).focus()
    } else if (!Util.areCookiesEnabled(this)) {
      this.$store.commit('snacks/error', 'Please enable cookies for equiam.com')
    } else {
      // Login when fields are okay
      this.performLogin()
    }
  }

  performLogin() {
    this.loading = true
    this.$axios.post(`/auth/log-in`, { 
      email: this.email,
      password: this.password,
      // twofa: this.code && this.code.length ? this.code : undefined,
    } as ILogInParams)
        .then(async ({data}: AxiosResponse<ILogInResult>) => {
          if (!data.authenticated && data.requiresTwoFa) {
            if (this.$route.path === this.routes.twoFactorAuth) {
              // Invalid 2FA code
              this.codeErrorMessages = ['Your code is invalid. Contact us if you experience issues']
              ;(this.$refs.code as HTMLElement).focus()
            } else {
              // Valid username / password. Go to 2FA page
              this.$router.push(
                this.routes.twoFactorAuth +
                  (this.$route.query.redirect ? '?redirect=' + encodeURIComponent(this.$route.query.redirect as string) : '')
              )
              this.send2FaNotification()
            }
            this.loading = false
          } else if (!data.authenticated) {
            // Login failed
            this.password = ''
            this.numLoginFailed++
            this.passwordErrorMessages = ['Your credentials are invalid']
            if (this.numLoginFailed > 1) {
              this.passwordErrorMessages = [
                `Your credentials are invalid.
                You can reset your password by clicking the link below.`,
              ]
            }
            ;(this.$refs.password as HTMLElement).focus()
            this.loading = false
          } else {
            // Successful login
            Util.updateVuexPostLogin(this, data.session)
            // TODO: must identify here
            
            if (this.$route.query.redirect) {
              if (this.$route.query.redirect.toString().toLowerCase().indexOf('http') === 0) {
                try {
                  const url = new URL(decodeURIComponent(this.$route.query.redirect as string))
                  this.$router.replace(url.pathname + url.search)
                } catch {
                  this.$router.replace(this.routes.investorPortal)
                }
              } else {
                this.$router.replace(decodeURIComponent(this.$route.query.redirect as string))
              }
            } else {
              this.$router.replace(this.routes.investorPortal)
            }
          }
        })
        .catch((e: any) => this.$store.commit('snacks/error', e))
        .finally(() => this.loading = false)
  }

  send2FaNotification() {
    // this.$mutate({
    //   client: 'auth',
    //   mutation: TwoFactorSmsQuery,
    //   variables: {
    //     email: this.email,
    //   },
    // }).then((result: TwoFactorSmsMutation) => {
    //   if (!result.requestTwoFactorSMS) {
    //     this.$store.commit('snacks/error', 'Two-factor authentication failed.')
    //   }
    // })
  }

  @Watch('$route')
  onRouteChange({ path }: { path: string }) {
    this.state = path === this.routes.twoFactorAuth ? '2fa' : 'login'
    if (this.state === '2fa') {
      Vue.nextTick(() => this.$refs.code && (this.$refs.code as HTMLElement).focus())
    }
  }
}
